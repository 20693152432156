import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  NormalizedCacheObject,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { checkIsServer } from './envHelpers'
import getConfig from 'next/config'
import { GetServerSidePropsContext } from 'next'
import { getCookie } from '@libs/cookies'

const { publicRuntimeConfig } = getConfig()
let globalApolloClient

/**
 * 會根據 SSR/CSR 判斷要怎麼拿 auth token
 */
function createApolloClient(context?: GetServerSidePropsContext) {
  const ssrMode = checkIsServer()

  const errorLink = onError(({ networkError }) => {
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  const httpLink = createHttpLink({
    uri: ssrMode ? publicRuntimeConfig.graphqlServerEndpoint : publicRuntimeConfig.graphqlClientEndpoint,
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  })

  const authLink = setContext((_, { headers }) => {
    // TODO: CSR 暫時從 LocalStorage 拿 token，待後端讓 Apollo Server 支援 cookie 後可移除 CSR 部分
    const token = context
      ? getCookie('user.token', context)
      : ssrMode
      ? null
      : JSON.parse(window.localStorage.getItem('user.token'))

    if (!token) return { headers }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Popdaily ${token}`,
      },
    }
  })

  return new ApolloClient({
    ssrMode,
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  })
}

export function getApolloClient(
  context?: GetServerSidePropsContext,
  initialState?: any,
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = globalApolloClient ?? createApolloClient(context)

  if (initialState) {
    _apolloClient.cache.restore({
      ..._apolloClient.cache.extract(),
      ...initialState,
    })
  }

  // SSR 或 SSG 要回傳新的 apollo client instance，以免跟 _app 的衝突
  if (checkIsServer()) {
    return _apolloClient
  }

  // CSR 確保只有 create 一次
  if (!globalApolloClient) {
    globalApolloClient = _apolloClient
  }

  return _apolloClient
}
